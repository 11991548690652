module.exports = {
    indexPath: "index.html",
    devServer: {
        port: 9999,
        host: "localhost",
        https: false,
        open: false,
        proxy: {
            "/api":{
                //设置后端主机
                // target: "http://127.0.0.1:9100",
                target: "https://fmdata.top",
                changeOrigin: true,
                ws: true,
                pathRewrite: {
                    "^/api": ""
                }
            }
        }
    }
}